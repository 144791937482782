import * as React from "react";
import "../style/history.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";


import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";

import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Help = (props) => {
  const { dialog, setDialog } = props;

  const handleClose = () => {
    setDialog(false);
  };


  return (
    <>
      <Dialog
        fullScreen
        open={dialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative" }}
          class="bg-white border-bottom sticky-top"
        >
          <Toolbar>
            <Typography
              sx={{ ml: 1, flex: 1 }}
              variant="h6"
              component="div"
              className="text-dark"
            >
              <span>{"< "}</span>
              Game Rules
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose} className="fw-bold">
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <div>
          <div class="row rules">
            <div className="col-8" style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
              <span style={{ color: "#026098", fontWeight: 700 }}>
                OBJECTIVE OF TEEN PATTI
              </span>
              <span>Have the best three cards in your hand and maximize the pot before the showdown.</span>


              <span style={{ color: "#026098", fontWeight: 700, marginTop: 20 }}>
                NUMBER OF PLAYERS
              </span>
              <span>3-6 Players</span>

              <span style={{ color: "#026098", fontWeight: 700, marginTop: 20 }}>
                NUMBER OF CARDS
              </span>
              <span>52 card deck</span>


              <span style={{ color: "#026098", fontWeight: 700, marginTop: 20 }}>
                RANK OF CARDS
              </span>
              <span>A (High), K, Q, J, 10, 9, 8, 7, 6, 5, 4, 3, 2</span>

              <span style={{ color: "#026098", fontWeight: 700, marginTop: 20 }}>
                THE DEAL | TEEN PATTI
              </span>
              <span>
                Teen Patti is a 3-card poker game for 3 to 6 players. The objective is to have the best 3-card hand and to place bets before the showdown.
                Each player in the game is given 3 cards face-down. Before the deal, a boot amount is agreed upon and collected from each player. This is the minimum stake put into the pot (the money kept in the center of the table). As the game progresses, the pot grows and is won by the winner of that hand. The winner is the player who remains in the game for the whole hand and has the highest or best hand.
              </span>

              <span style={{ color: "#026098", fontWeight: 700, marginTop: 20 }}>
                RANKING OF HANDS
              </span>
              <span>
                Hands ranked from highest to lowest:

                <ol>
                  <li>
                    Trail (Three of a Kind/Set/Trio): Three cards of the same rank. Aces are the highest, twos are the lowest.
                  </li>
                  <li>
                    Pure Sequence (Straight Flush/Run): Three consecutive cards within the same suit. For example, A-K-Q of diamonds.
                  </li>

                  <li>
                    Sequence (Straight/Normal Run): Three consecutive cards not within the same suit.
                  </li>

                  <li>
                    Color (Flush/Colous): Three cards in the same suit but not in sequence. In the event of comparing two colors, compare the hig
                  </li>

                  <li>
                    Pair (Two of a Kind): Two cards that are the same rank. In comparing these hands, first, compare the pair. If the pair is equal, the highest oddball card wins. A-A-K is the highest pair and 2-2-3 is the lowest.
                  </li>

                  <li>
                    High Card: If the three cards do not fit in the above categories, compare the highest card first (then second and so on). The best hand is A-K-J (with mixed suits) and the lowest is 5-3-2.
                  </li>
                </ol>
              </span>



            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Help;
